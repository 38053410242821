import React from 'react';
import Layout, { Content } from '../components/Layout';
import { graphql, useStaticQuery } from 'gatsby';

const Status = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        id
        buildTime(fromNow: false)
      }
    }
  `);

  return (
    <Layout title='Status'>
      <Content>Build Time: {String(data.site.buildTime)}</Content>
    </Layout>
  );
};

export default Status;
